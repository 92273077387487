import React from 'react';
import { RedocStandalone } from 'redoc';
import './App.css';

function App() {
  return (
    <div className="App">
    <RedocStandalone
        spec={"openapi.yaml"}
        options={{
        nativeScrollbars: true,
        theme: { colors: { primary: { main: '#08CCF3' } } },
        }}
      />
    </div>
  );
}

export default App;
